<div class="content">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Administración</li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="title">
                        <h3 class="title">Agregar</h3>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <app-loading-data *ngIf="loadingData"></app-loading-data>

    <div class="card card-block animated fadeIn fast">
        <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h2>{{ teacher.name }} <small>({{ teacher.rfc }})</small></h2>
            </div>
            <hr />
        </div>
        <form [formGroup]="forma" (ngSubmit)="sendForm()">
            <div class="row form-group">
                <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Origen</label>
                    <select class="custom-select" name="origin" formControlName="origin">
                        <option value="">Seleccionar...</option>
                        <option value="Abono" >Abono</option>
                        <option value="Ahorro" >Ahorro</option>
                    </select>
                </div>

                <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Forma de pago</label>
                    <select class="custom-select" name="wayToPay" formControlName="wayToPay">
                        <option value="">Seleccionar...</option>
                        <option value="Cheque" >Cheque</option>
                        <option value="Transferencia" >Transferencia</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['movementDate'].valid && forma.controls['movementDate'].touched, 'has-success': forma.controls['movementDate'].valid && forma.controls['movementDate'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha</label>
                        <input type="date" class="form-control underlined" formControlName="movementDate">
                        <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.required" class="has-error ">
                          El campo es requerido.
                      </span>
                        <span *ngIf="forma.controls['movementDate'].touched && forma.controls['movementDate'].errors?.minlength" class="has-error">
                          El campo debe tener al menos {{ forma.controls['movementDate'].errors.minlength.requiredLength }}
                          caracteres.
                      </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['amount'].valid && forma.controls['amount'].touched, 'has-success': forma.controls['amount'].valid && forma.controls['amount'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Importe</label>
                        <input disabled *ngIf="disabledAmount() " type="number" class="form-control underlined" formControlName="amount">
                        <input *ngIf="!disabledAmount()" type="number" class="form-control underlined" formControlName="amount">
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['amount'].touched && forma.controls['amount'].errors?.min" class="has-error">
                            El valor mínimo es 1.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group">
                        <label class="control-label">Cobra otra persona?</label>
                        <select class="custom-select" name="wayToPay" formControlName="otherPerson">
                            <option value="false" selected >No</option>
                            <option value="true" >Si</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row form-group" *ngIf="forma.controls['origin'].value === 'Abono'">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['capital'].valid && forma.controls['capital'].touched, 'has-success': forma.controls['capital'].valid && forma.controls['capital'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Capital</label>
                        <input disabled *ngIf="disabledAmount() " type="number" class="form-control underlined" formControlName="capital">
                        <input *ngIf="!disabledAmount()" type="number" class="form-control underlined" formControlName="capital">
                        <span *ngIf="forma.controls['capital'].touched && forma.controls['capital'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['capital'].touched && forma.controls['capital'].errors?.min" class="has-error">
                            El valor mínimo es 1.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['interest'].valid && forma.controls['interest'].touched, 'has-success': forma.controls['interest'].valid && forma.controls['interest'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Interes</label>
                        <input disabled *ngIf="disabledAmount() " type="number" class="form-control underlined" formControlName="interest">
                        <input *ngIf="!disabledAmount()" type="number" class="form-control underlined" formControlName="interest">
                        <span *ngIf="forma.controls['interest'].touched && forma.controls['interest'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['interest'].touched && forma.controls['interest'].errors?.min" class="has-error">
                            El valor mínimo es 0.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['iva'].valid && forma.controls['iva'].touched, 'has-success': forma.controls['iva'].valid && forma.controls['iva'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> IVA</label>
                        <input disabled *ngIf="disabledAmount() " type="number" class="form-control underlined" formControlName="iva">
                        <input *ngIf="!disabledAmount()" type="number" class="form-control underlined" formControlName="iva">
                        <span *ngIf="forma.controls['iva'].touched && forma.controls['iva'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['iva'].touched && forma.controls['iva'].errors?.min" class="has-error">
                            El valor mínimo es 0.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['prima'].valid && forma.controls['prima'].touched, 'has-success': forma.controls['prima'].valid && forma.controls['prima'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Prima</label>
                        <input disabled *ngIf="disabledAmount() " type="number" class="form-control underlined" formControlName="prima">
                        <input *ngIf="!disabledAmount()" type="number" class="form-control underlined" formControlName="prima">
                        <span *ngIf="forma.controls['prima'].touched && forma.controls['prima'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['prima'].touched && forma.controls['prima'].errors?.min" class="has-error">
                            El valor mínimo es 0.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group" *ngIf="forma.controls['otherPerson'].value === 'true'">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['rfc'].valid && forma.controls['rfc'].touched, 'has-success': forma.controls['rfc'].valid && forma.controls['rfc'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> R.F.C.</label>
                        <input type="text" class="form-control underlined" maxlength="13" formControlName="rfc">
                        <span *ngIf="forma.controls['rfc'].touched && forma.controls['rfc'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['rfc'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['curp'].valid && forma.controls['curp'].touched, 'has-success': forma.controls['curp'].valid && forma.controls['curp'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> C.U.R.P.</label>
                        <input type="text" class="form-control underlined" maxlength="18" formControlName="curp">
                        <span *ngIf="forma.controls['curp'].touched && forma.controls['curp'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['curp'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-6">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['name'].valid && forma.controls['name'].touched, 'has-success': forma.controls['name'].valid && forma.controls['name'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Nombre</label>
                        <input type="text" class="form-control underlined" maxlength="250" formControlName="name">
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.required" class="has-error ">
                            El campo es requerido.
                        </span>
                        <span *ngIf="forma.controls['name'].touched && forma.controls['name'].errors?.minlength" class="has-error">
                            El campo debe tener al menos {{ forma.controls['name'].errors.minlength.requiredLength }}
                            caracteres.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row form-group" *ngIf="forma.controls['wayToPay'].value === 'Transferencia'">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['email'].valid && forma.controls['email'].touched, 'has-success': forma.controls['email'].valid && forma.controls['email'].touched }">
                        <label class="control-label"> <i class="fas fa-asterisk required"></i> Correo Electrónico</label>
                        <input type="text" maxlength="50" class="form-control underlined" formControlName="email">
                        <span *ngIf="forma.controls['email'].touched && forma.controls['email'].errors?.email" class="has-error">
                            el formato del correo es incorrecto.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['phone'].valid && forma.controls['phone'].touched, 'has-success': forma.controls['phone'].valid && forma.controls['phone'].touched }">
                        <label class="control-label"><i class="fas fa-asterisk required"></i> Teléfono <small>6141112244</small> </label>
                        <input type="tel" maxlength="20" class="form-control underlined" name="phone" formControlName="phone">
                        <span *ngIf="forma.controls['phone'].touched && forma.controls['phone'].errors?.pattern" class="has-error">
                            el formato del teléfono es incorrecto.
                        </span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Banco</label>
                    <select class="custom-select" formControlName="bankId">
                        <option value="">Seleccionar...</option>
                        <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
                    </select>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <label class="control-label"><i class="fas fa-asterisk required"></i> Cuenta Bancaria</label>
                    <input type="text" class="form-control underlined" maxlength="18" formControlName="account">
                </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group" [ngClass]="{'has-error': !forma.controls['comment'].valid && forma.controls['comment'].touched, 'has-success': forma.controls['comment'].valid && forma.controls['comment'].touched }">
                        <label class="control-label"> Comentario</label>
                        <input type="text" class="form-control underlined" maxlength="250" formControlName="comment">
                    </div>
                </div>
            </div>

            <div class="form-group text-center ">
                <button class="btn btn-outline-success" [disabled]="!formValid()" >
                  <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
                  <span *ngIf="!sendingData "> Guardar</span>
                  <span *ngIf="sendingData "> Guardando...</span>
              </button>
            </div>

            <app-error-message *ngIf="errorMessage" [message]="errorMessage"></app-error-message>
        </form>
    </div>

    <div class="form-group ">
        <a class="btn btn-outline-info " [routerLink]="['/administration/teachers'] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

</div>
